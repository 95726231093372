import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { startLoginEmailPassword } from "../../actions/auth";
import { useForm } from "../../hooks/useForm";

export const LoginScreen = () => {

    const dispatch = useDispatch();
    const {loading} = useSelector(state => state.ui);

    const [formValues, handleInputChange] = useForm({
        email: '',
        password: ''
    })

    const {email, password} = formValues;

    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(startLoginEmailPassword(email, password));
    }

    return (
        <div className="auth__main">
            <div className="auth__box-container">
                <div>

                    <h3 className="auth__title">Login</h3>
                    <form onSubmit={handleLogin}>
                        <input value={email} onChange={handleInputChange} type="text" placeholder="Email" name="email" className="auth__input" autoComplete="off"/>
                        <input value={password} onChange={handleInputChange} type="password" placeholder="Password" name="password" className="auth__input"/>

                        <button type="submit" className="mybtn mybtn-primary" disabled={loading}>Login</button>
                    </form>
                </div>
            </div>
        </div>
    )
}
