import React from 'react';

export const ArchiveCard = ({image, title, description, date, url}) => {
    return (
        <div className="col">
            <div className="card h-100 card-filling">
                <img src={image} className="card-img-top" alt="..." />
                <div className="card-body" height="100%">
                    <h5 className="card-title card-title-filling">{title}</h5>
                    <p className="card-text card-text-filling">{description}</p>
                    <div className="d-grid gap-2">
                        <a className="btn btn-primary card-button"
                            href={url}>
                            <i className="fa fa-external-link-alt fa-1x" /> Allá voy!
                        </a>
                    </div>
                </div>
                <div className="card-footer">
                    <small className="text-muted card-date">{date}</small>
                </div>
            </div>
        </div>
    )
}
