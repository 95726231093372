import React from 'react';

import './ArchiveScreen.css';

import { ArchiveCard } from './ArchiveCard';

export const ArchiveScreen = () => {

    const newsletters = [
        {
            'image': require('../../assets/campanhas/campanha_20.png').default,
            'title': "#20 ASAP",
            'description': 'Spoiler: Esta es la última newsletter publitransformadora de la temporada (que no del año).',
            'date': '6 de agosto de 2022',
            'url': 'https://us5.campaign-archive.com/?u=d2b7c97115c60aff20c45095d&id=005fecddab'
        },
        {
            'image': require('../../assets/campanhas/campanha_19.png').default,
            'title': "#19 Bury Your Gays",
            'description': 'Spoiler: “Tengo tres hijos… Imaginad, ¿cómo son?… ¡venga!”',
            'date': '23 de julio de 2022',
            'url': 'https://us5.campaign-archive.com/?u=d2b7c97115c60aff20c45095d&id=c57ce3d565'
        }, 
        {
            'image': require('../../assets/campanhas/campanha_18.jpg').default,
            'title': "#18 Edadismo y publicidad",
            'description': 'Spoiler: No hay edad para ser target de la publicidad. Hablamos de juniors, seniors... y del edadismo y la creatividad.',
            'date': '9 de julio de 2022',
            'url': 'https://us5.campaign-archive.com/?u=d2b7c97115c60aff20c45095d&id=01377eb452'
        },
        {
            'image': require('../../assets/campanhas/campanha_17.jpeg').default,
            'title': "#17 Entrevista a Marelisa Blanco",
            'description': 'Spoiler: Marelisa nos ayuda a combatir el gap sobre el conocimiento del Convenio de la Publicidad.',
            'date': '25 de junio de 2022',
            'url': 'https://us5.campaign-archive.com/?u=d2b7c97115c60aff20c45095d&id=0f502396de'
        }, {
            'image': require('../../assets/campanhas/campanha_16.jpeg').default,
            'title': "#16 Publicistas: cuál es vuestro oficio?",
            'description': 'Spoiler: Primeros resultados de la Encuesta Salarial Colaborativa de Publicidad 2022.',
            'date': '11 de junio de 2022',
            'url': 'https://us5.campaign-archive.com/?u=d2b7c97115c60aff20c45095d&id=065aede992'
        }, {
            'image': require('../../assets/campanhas/campanha_15.jpg').default,
            'title': "#15 El Método v3",
            'description': 'Spoiler: una fórmula para ver mejor, una forma de ejercitar la perspectiva de género en una pieza publicitaria.',
            'date': '28 de mayo de 2022',
            'url': 'https://us5.campaign-archive.com/?u=d2b7c97115c60aff20c45095d&id=ad8c24e628'
        },
        {
            'image': require('../../assets/campanhas/campanha_14.jpg').default,
            'title': "#14 Entrevista a Cristina Guezuraga",
            'description': 'Spoiler: si todavía no sabes quiénes son Teresa Mesaesquinera o Jessi Yes... esta entrevista seguramente te venga muy bien.',
            'date': '14 de mayo de 2022',
            'url': 'https://us5.campaign-archive.com/?u=d2b7c97115c60aff20c45095d&id=6d1a52b359'
        },
        {
            'image': require('../../assets/campanhas/campanha_12.jpg').default,
            'title': "#12 Entrevista a Alba Cobo",
            'description': 'Spoiler: diversidad corporal, gordofobia, re-enfocar el concepto salud y belleza... y responsabilidad publicitaria.',
            'date': '16 de abril de 2022',
            'url': 'https://us5.campaign-archive.com/?u=d2b7c97115c60aff20c45095d&id=7d2f80a314'
        },
        {
            'image': require('../../assets/campanhas/campanha_11.jpg').default,
            'title': "#11 Dejemos correr la sangre libre de unicornios",
            'description': 'Spoiler: eso que cuesta tanto pronunciar, mostrar y narrar en publicidad: la regla.',
            'date': '2 de abril de 2022',
            'url': 'https://us5.campaign-archive.com/?u=d2b7c97115c60aff20c45095d&id=37fcf587f6'
        }, {
            'image': require('../../assets/campanhas/campanha_10.jpg').default,
            'title': "#10 Entrevista a Alejandra Chacón",
            'description': 'Spoiler: estrategia publicitaria, píldoras de colores y un viaje por el espacio-tiempo para tomar perspectiva.',
            'date': '19 de marzo de 2022',
            'url': 'https://us5.campaign-archive.com/?u=d2b7c97115c60aff20c45095d&id=d841348793'
        }, {
            'image': require('../../assets/campanhas/campanha_9.jpg').default,
            'title': "#9 La pseudo-publicidad del pseudo-feminismo",
            'description': 'Spoiler: el 8M, la Señora Coherencia, la publicidad violeta y el feminismo universal.',
            'date': '5 de marzo de 2022',
            'url': 'https://us5.campaign-archive.com/?u=d2b7c97115c60aff20c45095d&id=be62fbb8f3'
        }, {
            'image': require('../../assets/campanhas/campanha_8.jpg').default,
            'title': "#8 Entrevista a María Martín",
            'description': 'Spoiler: y el lenguaje inclusivo y no sexista en publicidad, ¿pa\'cuándo?',
            'date': '19 de febrero de 2022',
            'url': 'https://us5.campaign-archive.com/?u=d2b7c97115c60aff20c45095d&id=7bc096da9f'
        }, {
            'image': require('../../assets/campanhas/campanha_7.jpg').default,
            'title': "#7 Vamos a contar mentiras tralará!",
            'description': 'Spoiler: McFlurrys en publicidad, derecho a la pereza, hacer comunidad y trabajar 25 horas/semana.',
            'date': '5 de febrero de 2022',
            'url': 'https://us5.campaign-archive.com/?u=d2b7c97115c60aff20c45095d&id=9d577fffaf'
        }, {
            'image': require('../../assets/campanhas/campanha_5.jpg').default,
            'title': "#5 Entrevista a Rocío Restaino",
            'description': 'Spoiler: ¿Qué mundo estamos ayudando a construir con las ideas que creamos?',
            'date': '22 de enero de 2022',
            'url': 'https://us5.campaign-archive.com/?u=d2b7c97115c60aff20c45095d&id=d76d63e280'
        }, {
            'image': require('../../assets/campanhas/campanha_4.jpg').default,
            'title': "#4 La mejor campaña de la historia",
            'description': 'Spoiler: vamos a hablar de precariedad, transparencia y de la idea romántica de la publicidad.',
            'date': '8 de enero de 2022',
            'url': 'https://us5.campaign-archive.com/?u=d2b7c97115c60aff20c45095d&id=e1100a1d88'
        }, {
            'image': require('../../assets/campanhas/campanha_3.jpg').default,
            'title': "#3 Aprender del caso 'Campanadas Pedroche'",
            'description': 'Spoiler: vamos a hablar de los límites de la publicidad y cómo podemos re-enfocar el debate.',
            'date': '25 de diciembre de 2021',
            'url': 'https://us5.campaign-archive.com/?u=d2b7c97115c60aff20c45095d&id=919bf2db02'
        }, {
            'image': require('../../assets/campanhas/campanha_2.png').default,
            'title': '#2 Domingo a la tarde, fui a los coches de choque',
            'description': 'Spoiler: vamos a hablar de Autocontrol, qué está fallando?',
            'date': '11 de diciembre de 2021',
            'url': 'https://us5.campaign-archive.com/?u=d2b7c97115c60aff20c45095d&id=214d0c5b6d'
        }, {
            'image': require('../../assets/campanhas/campanha_1.jpg').default,
            'title': '#1 Lo escuchan? Es el silencio 😒',
            'description': 'Spoiler para impacientes: vamos a hablar del "silencio" en el sector de la publicidad.',
            'date': '27 de noviembre de 2021',
            'url': 'https://us5.campaign-archive.com/?u=d2b7c97115c60aff20c45095d&id=4e224545a7'
        }]

    return (
        <div className='first-container'>
            <div className="desktop-newsletters " >
                <div className='show-desktop' style={{ marginBottom: '-25px', }}>
                    <h1 style={{ marginTop: '44px', fontSize: '23px', textAlign: 'center', backgroundColor: 'black', padding: '10px', color: 'white' }} className='intention-text '>Newsletters #Publitransformadoras</h1>
                </div>

                <div className="row row-cols-1 row-cols-md-3 g-4" style={{ margin: '10px 15px 50px 15px', paddingBottom: '50px' }}>

                    {newsletters.map((newsletter, index) => {
                        return <ArchiveCard
                            key={index}
                            image={newsletter.image}
                            title={newsletter.title}
                            description={newsletter.description}
                            date={newsletter.date}
                            url={newsletter.url} />
                    })}

                </div>
            </div>
        </div>
    )
}
