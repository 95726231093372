import { db, collection, getDocs } from "../firebase/firebaseConfig";

export const loadNewsletters = async () => {
    const newslettersSnapshot = await getDocs(collection(db, "newsletters"));

    const newsletters = [];

    newslettersSnapshot.forEach((doc) => {
        newsletters.push({
            id: doc.id,
            ...doc.data()
        })
    });

    return newsletters;
}