export const fileUpload = async (file) => {

    const cloudinaryUrl = 'https://api.cloudinary.com/v1_1/dpcgzd0mj/image/upload';

    // return image url

    const formdata = new FormData();
    formdata.append("upload_preset", "filling_thegap");
    formdata.append("file", file);

    const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    try {

        const resp = await fetch(cloudinaryUrl, requestOptions)
    
        if (resp.ok) {
            const cloudResp = await resp.json();
            return cloudResp.secure_url;
        } else {
            throw await resp.json();
        }
    } catch (error) {
        console.log(error);
    }
}