import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeNewsletter, startSaveNewsletter, startUploading } from '../../actions/newsletter';
import { useForm } from "../../hooks/useForm";

export default function NewsletterModal() {

    // Obtenemos el useDispatch para poder ejectura acciones contra el state
    const dispatch = useDispatch();

    // Obtenemos la newsletter activa del estado
    const newsletter = useSelector(state => state.newsletters.active);

    const [formValues, handleInputChange, reset] = useForm(newsletter);
    let image = '';
    let title = '';
    let description = '';
    let url = '';
    let date = '';

    if (formValues) {
        image = formValues.image;
        title = formValues.title;
        description = formValues.description;
        url = formValues.url;
        date = formValues.date;
    }


    const activeId = useRef(0);
    const activeImg = useRef(0);

    useEffect(() => {
        if ((newsletter) && ((newsletter.id !== activeId.current) || (newsletter.image !== activeImg.current))) {
            reset(newsletter);
            activeId.current = newsletter.id;
            activeImg.current = newsletter.image;
        }

    }, [newsletter, reset]);

    useEffect(() => {
        if (formValues) {
            dispatch(activeNewsletter(formValues.id, { ...formValues }));
        }
    }, [formValues, dispatch]);

    const handleNewsletterModified = (e) => {
        e.preventDefault();
        dispatch(startSaveNewsletter({ ...formValues }));
    }

    const handleUpdateImage = () => {
        document.querySelector('#fileSelector').click();
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            dispatch(startUploading(file));
        }

    }   


    return (
        <div className="modal fade" id="newsletterModal" tabIndex="-1" aria-labelledby="newsletterModal" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content" style={{ backgroundColor: "transparent", border: "none" }}>
                    <div className="card h-100" style={{ margin: "20px", padding: "20px" }}>
                        <form onSubmit={handleNewsletterModified}>
                            <div>
                                <label className="form-label">Imagen</label>
                                <img src={image} className="card-img-top" alt="..." />
                                <br />
                                <br />
                                <input id="fileSelector" type="file" name="file" style={{display: 'none'}} onChange={handleFileChange}/>
                                <button type="button" className="btn btn-secondary" onClick={handleUpdateImage}>Subir imagen</button>
                            </div>
                            <br />

                            <label className="form-label">Tíutlo</label>
                            <input value={title} onChange={handleInputChange} type="text" placeholder="Título" name="title" className="auth__input" autoComplete="off" />

                            <label className="form-label">Descripción</label>
                            <input value={description} onChange={handleInputChange} type="text" placeholder="Descripción" name="description" className="auth__input" autoComplete="off" />

                            <label className="form-label">URL de la newsletter</label>
                            <input value={url} onChange={handleInputChange} type="text" placeholder="URL" name="durl" className="auth__input" autoComplete="off" />

                            <label className="form-label">Fecha de publicación</label>
                            <input value={date} onChange={handleInputChange} type="text" placeholder="Fecha" name="date" className="auth__input" autoComplete="off" />


                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <button type="submit" className="btn btn-primary">Guardar</button>
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    )
}
