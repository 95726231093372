import React from 'react'

export default function CookiesView() {
    return (
        <div className="modal fade" id="cookiesModal" tabIndex="-1" aria-labelledby="cookiesModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                {/* <div className="modal-dialog modal-fullscreen"> */}
                <div className="modal-content">
                    {/* Header */}
                    <div className="modal-header">
                        <h5 className="modal-title" id="cookiesModal">Cookies</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    {/* Body */}
                    <div className="modal-body">
                        <small>Bueu, 7 de noviembre de 2021</small>
                        <br />
                        <br />
                        <p>
                            Una cookie es un archivo de texto que se almacena en el ordenador o dispositivo móvil mediante un servidor Web y tan solo ese servidor será capaz de recuperar o leer el contenido de la cookie y permiten al Sitio Web recordar preferencias de navegación y navegar de manera eficiente. Las cookies hacen la interacción entre la usuaria o usuario y el sitio Web más rápida y fácil.
                        </p>

                        <h2>Información General</h2>
                        <p>
                            Está página Web utiliza cookies. Las cookies son pequeños archivos de texto generados por las páginas web que usted visita, las cuales contienen los datos de sesión que pueden ser de utilidad más adelante en la página web. De esta forma esta Web recuerda información sobre su visita, lo que puede facilitar su próxima visita y hacer que el sitio Web le resulte más útil.
                        </p>

                        <h2>¿Cómo funcionan las cookies?</h2>
                        <p>
                            Las cookies sólo pueden almacenar texto, por lo general siempre es anónimo y cifrado. No se almacenarán información personal alguna en una cookie, ni pueden asociarse a persona identificada o identificable. Los datos permiten que esta Web pueda mantener su información entre las páginas, y también para analizar la forma de interactuar con el sitio Web. Las cookies son seguras ya que sólo pueden almacenar la información que se puso en su lugar por el navegador, lo que es información que la usuaria o usuario ha introducido en el navegador o la que se incluye en la solicitud de página. No puede ejecutar el código y no se puede utilizar para acceder a su ordenador. Si una página web cifra la información de la cookie, sólo la página web puede leer la información.
                        </p>

                        <h2>¿Qué tipo de cookies utilizamos?</h2>
                        <p>
                            Las cookies que utiliza esta página Web se pueden distinguir según los siguientes criterios:
                        </p>
                        <ol>
                            <li>
                                <b>Tipos de cookies según la entidad que las gestiona:</b>
                                <p>
                                    Según quien sea la entidad que gestione el equipo o dominio desde donde se envían las cookies y trate los datos que se obtengan, podemos distinguir:
                                </p>
                                <ul>
                                    <li>
                                        Cookies propias: Son aquellas que se envían al equipo terminal de la usuaria o usuario desde un equipo o dominio gestionado por la propia editora o editor y desde el que se presta el servicio solicitado por la usuaria o usuario.
                                    </li>
                                    <li>
                                        Cookies de terceros: Son aquellas que se envían al equipo terminal de la usuaria o usuario desde un equipo o dominio que no es gestionado por la editora o editor, sino por otra entidad que trata los datos obtenidos a través de las cookies.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                En el caso de que las cookies sean instaladas desde un equipo o dominio gestionado por la propio editora o editor pero la información que se recoja mediante éstas sea gestionada por un tercero, no pueden ser consideradas como cookies propias.
                            </li>
                            <li>
                                <p>
                                    <b>Tipos de cookies según el plazo de tiempo que permanecen activadas:</b>
                                </p>
                                <p>
                                    Según el plazo de tiempo que permanecen activadas en el equipo terminal podemos distinguir:
                                </p>
                                <ul>
                                    <li>
                                        Cookies de sesión: Son un tipo de cookies diseñadas para recabar y almacenar datos mientras la usuaria o usuario accede a una página web. Se suelen emplear para almacenar información que solo interesa conservar para la prestación del servicio solicitado por la usuaria o usuario en una sola ocasión (p.e. una lista de productos adquiridos).
                                    </li>
                                    <li>
                                        Cookies persistentes: Son un tipo de cookies en el que los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios años.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    <b>Tipos de cookies según su finalidad:</b>
                                </p>
                                <p>
                                    Según la finalidad para la que se traten los datos obtenidos a través de las cookies, podemos distinguir entre:
                                </p>
                                <ul>
                                    <li>
                                        Cookies técnicas: Son aquellas que permiten a la usuaria o usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de vídeos o sonido o compartir contenidos a través de redes sociales.
                                    </li>
                                    <li>
                                        Cookies de personalización: Son aquellas que permiten a la usuaria o usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal de la usuaria o usuario como por ejemplo serian el idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde accede al servicio, etc.
                                    </li>
                                    <li>
                                        Cookies de análisis: Son aquellas que permiten a la responsable o el responsable de las mismas, el seguimiento y análisis del comportamiento de las usuarias o usuarios de los sitios web a los que están vinculadas. La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y para la elaboración de perfiles de navegación de las usuarias o usuarios de dichos sitios, aplicaciones y plataformas, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen las usuarias o usuarios del servicio.
                                    </li>
                                </ul>
                            </li>
                        </ol>


                        <h2>Herramienta de gestión de las cookies</h2>
                        <p>Está página Web utiliza Google Analytics.</p>

                        <p>
                            Google Analytics es una herramienta gratuita de análisis web de Google que principalmente permite que los propietarios de sitios web conozcan cómo interactúan las usuarias o usuarios con su sitio web. Asimismo, habilita cookies en el dominio del sitio en el que te encuentras y utiliza un conjunto de cookies denominadas “__utma” y “__utmz” para recopilar información de forma anónima y elaborar informes de tendencias de sitios web sin identificar a usuarios individuales. Para realizar las estadísticas de uso de esta Web utilizamos las cookies con la finalidad de conocer el nivel de recurrencia de nuestros visitantes y los contenidos que resultan más interesantes. De esta manera podemos concentrar nuestros esfuerzos en mejorar las áreas más visitadas y hacer que la usuaria o usuario encuentre más fácilmente lo que busca. En esta Web puede utilizarse la información de su visita para realizar evaluaciones y cálculos estadísticos sobre datos anónimos, así como para garantizar la continuidad del servicio o para realizar mejoras en sus sitios Web. Para más detalles, consulte en el siguiente enlace la <a href="http://www.google.com/intl/es/policies/privacy/">política de privacidad</a>.
                        </p>

                        <h2>Cómo gestionar las cookies en su equipo: La desactivación y eliminación de las cookies</h2>
                        <p>
                            Todos los navegadores de Internet le permiten limitar el comportamiento de una cookie o desactivar las cookies dentro de la configuración o las opciones del navegador. Los pasos para hacerlo son diferentes para cada navegador, se pueden encontrar instrucciones en el menú de ayuda de su navegador.
                        </p>
                        <p>
                            Si no acepta el uso de las cookies, ya que es posible gracias a los menús de preferencias o ajustes de su navegador, rechazarlas, este sitio Web seguirá funcionando adecuadamente sin el uso de las mismas.
                        </p>
                        <p>
                            Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador:
                        </p>
                        <ul>
                            <li>
                                Para más información sobre Chrome <a href="https://support.google.com/chrome/answer/95647?hl=es">pulse aquí</a>.
                            </li>
                            <li>
                                Para más información sobre Safari <a href="http://support.apple.com/kb/HT1677?viewlocale=es_ES&locale=es_ES">pulse aquí</a>.
                            </li>
                            <li>
                                Para más información sobre Firefox <a href="https://support.mozilla.org/es/kb/Borrar%20cookies">pulse aquí</a>.
                            </li>
                            <li>
                                Para más información sobre Internet Explorer <a href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9">pulse aquí</a> o cómo bloear, habilitar o permitir las cookies <a href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9">pulse aquí</a>.
                            </li>
                        </ul>


                    </div>


                    {/* Footer */}
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
