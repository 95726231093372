import React from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";

import CustomForm from './CustomForm';

const MailchimpFormContainer = props => {

    const postUrl = 'https://gmail.us5.list-manage.com/subscribe/post?u=d2b7c97115c60aff20c45095d&id=7e0da5aa7b';

    return (
        <div >
            <MailchimpSubscribe
                url={postUrl}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status} 
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            />
        </div>
    );
};

export default MailchimpFormContainer;