import React from 'react'

export default function TermsAndConditions() {
    return (
        <div className="modal fade" id="termsAndConditionsModal" tabIndex="-1" aria-labelledby="termsAndConditionsModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                {/* <div className="modal-dialog modal-fullscreen"> */}
                <div className="modal-content">
                    {/* Header */}
                    <div className="modal-header">
                        <h5 className="modal-title" id="termsAndConditionsModal">Términos y condiciones</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    {/* Body */}
                    <div className="modal-body">
                        <small>Bueu, 7 de noviembre de 2021</small>
                        <br />
                        <br />
                        <h2>1. Propiedad del sitio</h2>
                        <p>
                            1.1. El siguiente documento (denominado a partir de ahora como <b>«<b>TÉRMINOS DE USO»</b></b>) regula el uso de la web <b>fillingthegap.org</b> (de ahora en adelante <b>«el SITIO WEB»</b> ) gestionada por <b>Publitransformadoras.</b> (de ahora en adelante, <b>“Pubitransformadoras”</b>).
                        </p>
                        <p>
                            1.2. El <b>SITIO WEB</b> es propiedad de Publitransformadoras, una iniciativa personal sin ánimo de lucro.
                        </p>
                        <p>
                            1.3 Si deseas comunicarte con <b>Publitransformadoras</b>, puedes contactar con nosotras en la siguiente dirección de correo: <a href="mailto:publitransformadoras@gmail.com">publitransformadoras@gmail.com.</a>
                        </p>


                        <h2>2. Aceptación de los <b>términos de uso</b></h2>
                        <p>
                        2.1.La utilización del <b>SITIO WEB</b> otorga la condición de usuaria o usuario del <b>SITIO WEB</b> (de ahora en adelante, «<b>USUARIA/O»</b>) e implica la aceptación de todas las cláusulas de la versión de estos <b>TÉRMINOS DE USO</b> publicada en el momento en el que este acceda al <b>SITIO WEB</b>.En consecuencia, la <b>USUARIA/O</b> deberá leer detenidamente estos <b>TÉRMINOS DE USO</b> en cada una de las ocasiones en las que visite y pretenda usar el <b>SITIO WEB</b>, puesto que estos podrían haber sido modificados.
                        </p>
                        <p>
                        2.2.<b>Publitransformadoras</b> se reserva el derecho de modificar estos <b>TÉRMINOS DE USO</b> cuando considere conveniente, por lo que la <b>USUARIA/O</b> está obligada a revisarlos periódicamente para comprobar la versión en vigor.
                        </p>
                        <p>
                        2.3.La aplicación general de estos <b>TÉRMINOS DE USO</b> no excluye la posibilidad de que ciertos servicios del <b>SITIO WEB</b> o alguna de sus características estén sujetas a condiciones especiales de uso.En ese caso, la <b>USUARIA/O</b> será informada y se requerirá su aceptación antes de activar dichos servicios o características.
                        </p>

                        <h2>3. Objetivo</h2>
                        <p>
                        3.1.El <b>SITIO WEB</b> tiene como propósito principal informar a la  <b>USUARIA/O</b> sobre la lista de correo conocida como "filling_thegap" y otras de las actividades desarrolladas por Publitransformadoras, así como publicitar sus posibles servicios o productos.
                        </p>
                        <p>
                        3.2.<b>Publitransformadoras</b> puede -dependiendo del desarrollo y evolución de sus productos y servicios- expandir o modificar este <b>SITIO WEB</b>, incluyendo o sustituyendo nuevos servicios, actividades o contenidos.
                        </p>


                        <h2>4. Uso</h2>
                        <h5>
                        4.1.Condición de usuario
                        </h5>
                        <p>
                        4.1.1.Se considera como <b>USUARIA/O</b> a cualquier persona mayor de edad que acceda al <b>SITIO WEB</b> por cualquier motivo, sin que sea necesario ningún registro previo para que asuma tal condición ni se requiera del mismo el envío de datos personales.
                        </p>
                        <p>
                        4.1.2.Se proporciona acceso al <b>SITIO WEB</b> a la <b>USUARIA/O</b> únicamente para consultar, buscar o publicar información.
                        </p>
                        <h5>
                        4.2.Uso apropiado del sitio
                        </h5>
                        <p>
                        4.2.1.La <b>USUARIA/O</b> conoce y acepta que los contenidos, funcionalidades o servicios del <b>SITIO WEB</b> se ofrecen «tal cual» y sin ningún tipo de garantía.En el caso de que decida usarlos o difundirlos, se compromete a hacerlo bajo su propia responsabilidad.
                        </p>
                        <p>
                        4.2.2 La <b>USUARIA/O</b> conoce y acepta que el uso del <b>SITIO WEB</b> y los servicios ofrecidos por <b>Publitransformadoras</b> implica la aceptación de estos <b>TÉRMINOS DE USO</b> y se compromete a usar los mismos de forma apropiada y sin cometer ninguna actividad ilegal, violar derechos de terceros, infringir leyes de propiedad intelectual o cualquier otra legislación aplicable.
                        </p>
                        <p>
                        4.2.3.La <b>USUARIA/O</b> se compromete a no introducir o difundir ningún virus en el <b>SITIO WEB</b> -o en la infraestructura informática que lo soporta- que pueda causar daños y perjuicios a <b>Publitransformadoras</b> y sus usuarias/os.
                        </p>
                        <p>
                        4.2.4.La <b>USUARIA/O</b> acepta y se compromete a no llevar a cabo ninguna acción que pueda dañar la reputación y buen nombre de Publitransformadoras.
                        </p>


                        <h2>5. Responsabilidad</h2>
                        <h5>
                        5.1.Disponibilidad y uso del <b>SITIO WEB</b>
                        </h5>
                        <p>
                        <b>Publitransformadoras</b> no es responsable ante la <b>USUARIA/O</b> por el mal funcionamiento del sitio web, ni por la interrupción, demora, lentitud, pérdida o desconexiones en las comunicaciones y la transmisión de mensajes.
                        </p>
                        <p>
                        <b>Publitransformadoras</b> no garantiza ni se responsabiliza del funcionamiento continuo, constante e ininterrumpido del portal.
                        </p>
                        <h5>
                        5.2.Responsabilidad de la <b>USUARIA/O</b>
                        </h5>
                        <p>
                        A pesar del esfuerzo de <b>Publitransformadoras</b> para implementar los más altos estándares de seguridad, <b>Publitransformadoras</b> no se responsabiliza, ni directa ni indirectamente, por daños de ningún tipo causados a la <b>usuaria/o</b> como resultado de la presencia de virus u otros elementos en los servicios y contenidos del <b>SITIO WEB</b>.Tampoco será responsable de los daños y pérdidas causados a la <b>USUARIA/O</b> por errores de acceso y errores en el acceso, en las conexiones, transmisiones o interrupciones sin causa justificada del servicio, por intrusiones, virus informáticos, envíos publicitarios masivos de terceros o donde esas causas se deben a situaciones fuera del <b>SITIO WEB</b>, como defectos en el dispositivo de conexión de la <b>USUARIA/O</b>, deficiencia en su conexión, virus internos o problemas de comunicación del proveedor telefónico de la <b>usuaria/o</b>, o cuando estos sean por causa mayor.
                        </p>
                        <h5>
                        5.3 Exención de responsabilidad por links de terceros
                        </h5>
                        <p>
                        <b>Publitransformadoras</b> no será responsable, ni directa ni indirectamente, por los posibles daños de cualquier tipo causados a la <b>USUARIA/O</b> como resultado de su visita a otras páginas y sitios web conectados con el <b>SITIO WEB</b> mediante links («enlaces electrónicos») ya que no tiene capacidad ni técnica ni legal para controlar o supervisar el contenidos de esas webs externas.La <b>USUARIA/O</b> asume todos los riesgos derivados de la utilización de dichos links y la visita a otras páginas y/o aplicaciones web.
                        </p>
                        <h5>
                        5.4.Responsabilidad de terceros
                        </h5>
                        <p>
                        5.4.1.<b>Publitransformadoras</b> no se responsabiliza por actos realizados por terceros, fuera del sistema, que incluyen la ruptura de las medidas de seguridad, la realización de actos contra la <b>USUARIA/O</b> como el envío de virus informáticos, publicidad masiva o correo no deseado, correos electrónicos comerciales, interrupción del servicio y acceso a mensajes.
                        </p>
                        <p>
                        5.4.2.<b>Publitransformadoras</b> se compromete a adoptar las medidas técnicas, organizativas y legales para proteger las comunicaciones desde y hacia el <b>SITIO WEB</b> exigidas por la ley.Sin perjuicio de lo anterior, <b>Publitransformadoras</b> no puede garantizarlas o asegurarlas y, por tanto, no asume ninguna responsabilidad asociada con una brecha de seguridad en sus sistemas por parte de un tercero de mala fe.
                        </p>
                        <p>
                        5.4.3.<b>Publitransformadoras</b> no se responsabiliza por ningún daño causado por actos de fuerza mayor o por un rendimiento inadecuado del sitio web o sus servicios por razones ajenas a su control.Esto puede incluir la siguiente lista no exhaustiva: mal funcionamiento de los operadores de Internet, configuración incorrecta de la computadora de la <b>usuaria/o</b> o capacidad insuficiente del sistema informático para admitir el contenido y el alcance de la información que se muestra en el portal.
                        </p>


                        <h2>6. Propiedad intelectual del <b>sitio web</b></h2>
                        <p>
                        6.1.<b>Publitransformadoras</b> es propietaria de todos los derechos intelectuales y comerciales que permiten la utilización del dominio, marcas registradas, logos, aplicaciones, información y otros trabajos relacionados con el <b>SITIO WEB</b>, la tecnología y los contenidos asociados al mismo, o ha obtenido el permiso y licencias necesarios para hacerlo.
                        </p>
                        <p>
                        6.2.Los contenidos del <b>SITIO WEB</b>, -incluyendo diseños, aplicaciones, textos, imágenes y código fuente (denominados en conjunto como <b>«CONTENIDO»</b>)- están protegidos por derechos de propiedad intelectual.
                        </p>
                        <p>
                        6.3.El CONTENIDO de este <b>SITIO WEB</b> puede ser copiado, modificado y distribuido libremente, siempre que este sea usados sin ánimo de lucro, se respete el buen nombre de Publitransformadoras; y el derecho de atribución del mismo.
                        </p>

                        <h2>7. Privacidad y confidencialidad</h2>
                        <p>
                        La <b>USUARIA/O</b> conoce y acepta la Política de Privacidad del <b>SITIO WEB</b>
                        </p>


                        </div>


                    {/* Footer */}
                        <div className="modal-footer">
                        <button type ="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                        </div>
                        </div>
                        </div>
                    </div>
                    )
}
