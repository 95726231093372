import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNewsletter, startNewNewsletter } from '../../actions/newsletter';
import { useForm } from '../../hooks/useForm';
import { Newsletter } from './Newsletter';
import NewsletterModal from './NewsletterModal';


export const NewslettersScreen = () => {

    const {newsletters} = useSelector(state => state.newsletters);

    const dispatch = useDispatch();

    const [formValues, handleInputChange] = useForm({
        filter: ''
    })

    const {filter} = formValues;

    const handleClick = (e, newsletter) => {
        e.preventDefault();
        dispatch(selectNewsletter(newsletter));
    }

    const handleAddNew = () => {
        dispatch(startNewNewsletter());
    }

    return (
        <div className="desktop main-container">
            <h1>Newsletters</h1>
            <hr />
            <div className="list-group" style={{ paddingBottom: "80px" }}>
                {
                    newsletters.filter(newsletter => {
                        const query = filter?.toLowerCase();
                        return (
                            (newsletter.title.toLowerCase().indexOf(query) >= 0) 
                            || (newsletter.date.toLowerCase().indexOf(query) >= 0) 
                        )
                    }).map((newsletter) => {
                        return <Newsletter 
                                    key={newsletter.id} 
                                    {...newsletter}
                                    onClick={(e) => handleClick(e,newsletter)} 
                                />
                    })
                }
            </div>

            <NewsletterModal/>

            <div className="navbar navbar-light bg-light fixed-bottom">
                <div className="d-flex w-100 p-2">
                    <div className="input-group me-2 w-100">
                        <div className="input-group-text" id="btnGroupAddon"><i className="fas fa-filter"></i></div>
                        <input value={filter} name="filter" onChange={handleInputChange} type="text" className="form-control" placeholder="Input group example" aria-label="Input group example" aria-describedby="btnGroupAddon" />
                    </div>
                    <button className="btn btn-outline-secondary" type="submit" onClick={handleAddNew}>Añadir</button>
                </div>
            </div>

        </div>
    )
}