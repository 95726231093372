import Swal from "sweetalert2";

import { db, addDoc, collection, setDoc, doc  } from "../firebase/firebaseConfig";
import { fileUpload } from "../helpers/fileUpload";
import { loadNewsletters } from "../helpers/loadNewsletters";
import { types } from "../types/types";

export const newsletterSelected = () => ({
    type: types.newsletterSelected
})

export const newsletterDeselected = () => ({
    type: types.newsletterDeselected
})

export const selectNewsletter = (newsletter) => ({
    type: types.newsletterSelected,
    payload: newsletter
});

export const startNewNewsletter = () => {
    return async (dispatch) => {
        const newNewsletter = {
            title: '',
            image: '',
            description: '',
            date: '',
            url: ''
        }

        try {
            const docRef = await addDoc(collection(db, "newsletters"), newNewsletter);

            dispatch(activeNewsletter(docRef.id, newNewsletter));
        
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }
}

export const activeNewsletter = (id, newsletter) => ({
    type: types.newsletterActive,
    payload: {
        id,
        ...newsletter
    }
});

export const startLoadingNewsletters = () => {
    return async (dispatch) => {
        const newsletters = await loadNewsletters();
        dispatch(setNewsletters(newsletters));
    }
}


export const setNewsletters = (newsletters) => ({
    type: types.newsletterLoad,
    payload: newsletters
});

export const startSaveNewsletter = (newsletter) => {

    return async (dispatch) => {

        const newsletterToFirestore = {...newsletter};
        delete newsletterToFirestore.id;
        
        try {
            await setDoc(doc(db, "newsletters", newsletter.id), newsletter);        

            dispatch( refreshNewsletter(newsletter.id, newsletter));

            Swal.fire('Guardado', newsletter.title, 'success');
        } catch (e) {
            console.error("Error adding document: ", e);

            Swal.fire('Error', e, 'warning');
        }

        
    }
}

export const refreshNewsletter = (id, newsletter) => ({
    type: types.newsletterUpdate,
    payload: {
        id, newsletter
    }
})


export const startUploading = (file) => {
    return async (dispatch, getState) => {
        const {active} = getState().newsletters;

        Swal.fire({
            title: 'Subiendo imagen...',
            text: 'Por favor, espera',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        })
        
        const fileUrl = await fileUpload(file);
        active.image = fileUrl;

        dispatch(startSaveNewsletter(active))


        Swal.close();
    }
}