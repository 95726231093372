import React, { useState } from 'react';

const CustomForm = ({ status, message, onValidated }) => {

    const emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    const [formState, setFormState] = useState({
        email: '',
        validEmail: true
    });

    const { email, validEmail } = formState;


    const handleInputChange = ({ target }) => {
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handledSubscribe = (e) => {
        e.preventDefault();

        if (!emailPattern.test(email)) {
            setFormState({ ...formState, validEmail: false });
        } else {
            setFormState({ ...formState, validEmail: true });

            onValidated({
                EMAIL: email
            });
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          handledSubscribe(e);
        }
      }

    return (


        <form className="mail-block">
            <div className="form-group">

                {status === "sending" && (
                    <div className="alert alert-primary" role="alert">
                        enviando...
                    </div>
                )}
                {status === "error" && (
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        Ups... Algo no fue bien :-(
                        {/* <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"/> */}
                    </div>
                )}
                {status === "success" && (
                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                        Éxito!! Comprueba tu email :-)
                        {/* <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" /> */}
                    </div>
                )}
                <input type="email"
                    className="form-control input-email-box"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Escribe tu email (fill the gap, literal)"
                    name="email"
                    autoComplete="off"
                    value={email}
                    onChange={handleInputChange}
                    onFocus={() => setFormState({ ...formState, validEmail: true })} 
                    onKeyDown={handleKeyDown} />

                {
                    (!validEmail) &&
                    <div className="alert alert-warning" style={{ marginTop: '4px' }}>
                        <span>Ups! Email no válido</span>
                    </div>
                }

            </div>
            <div className="d-grid gap-2">
                <button type="button" className="btn btn-primary email-subscribe-button"
                    onClick={handledSubscribe}>
                    <i className="fas fa-check-circle fa-1x" /> Me apunto a la lista!
                </button>
            </div>
        </form>
    );
};


export default CustomForm;