import React from 'react';

export const Newsletter = ({ id, image, title, description, date, url, onClick }) => {
    return (
        <div href="#"
            className="list-group-item list-group-item-action align-items-start d-flex"
            onClick={onClick}
            data-bs-toggle="modal"
            data-bs-target="#newsletterModal">
            <div className="flex-shrink-0">
                <img src={image} alt="..." width="150px" />
            </div>
            <div className="flex-grow-1 ms-3">
                <div className="flex-column">
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-2">{title}</h5>
                    </div>
                    <small>{date}</small>
                    <p className="mb-1">{description}</p>
                    <small><a href={url}>Enlace a newsletter</a></small>
                </div>
            </div>
        </div>
    )
}
