import {initializeApp } from 'firebase/app';
import {getFirestore, collection, addDoc, getDocs, setDoc, doc} from 'firebase/firestore';
import {getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut} from 'firebase/auth';

// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC_S5cK0H8IdHiZ8K101whBJTVH8SM-wE0",
  authDomain: "fillingthegap-dde06.firebaseapp.com",
  projectId: "fillingthegap-dde06",
  storageBucket: "fillingthegap-dde06.appspot.com",
  messagingSenderId: "619001008443",
  appId: "1:619001008443:web:2a3d1f6a3a27f29761e71e",
  measurementId: "G-7VMKWQD5FD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export {
    db,
    app,
    getAuth,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    collection, 
    addDoc,
    getDocs,
    setDoc,
    doc
}