import React, { useEffect } from 'react';
import CookieConsent, { getCookieConsentValue, Cookies } from "react-cookie-consent";

import '../../styles/styles.scss';

import { initGA } from '../../utils/ga-utils';

import MailchimpFormContainer from '../ui/CustomForm/MailchimpFormContainer';
import TermsAndConditions from '../ui/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from '../ui/PrivacyPolicy/PrivacyPolicy';
import CookiesView from '../ui/Cookies/CookiesView';

export const HomeScreen = () => {

  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  return (
    <div className="desktop">
      
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText="Bueeeno vaaaale"
        declineButtonText="No, graaacias"
        cookieName="myAwesomeCookieName5"
        style={{ background: "#2B373B" }}
        buttonStyle={{ backgroundColor: "#FFD500", color: "#4e503b", fontSize: "13px" }}
        declineButtonStyle={{ backgroundColor: "#FFFFFF", color: "#4e503b", fontSize: "13px" }}
        expires={150}
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
      >
        Esta página web usa cookies para mejorar tu experiencia.{" "}
      </CookieConsent>


      <div className="first-container">
        <div className="rectangle-background" />
        <div className="start-background" />
        <div className="logo-container">
          <div className="logo-img" />
          <div className="logo-text">
            <span className="logo-text">ReanimaciónCardioPulmonar</span>
            <div style={{ marginTop: '-5px' }}>
              <span className="logo-text">para </span>
              <span className="logo-text-bold">#Publitransformadoras</span>
            </div>
          </div>

          {/* Text */}
          <div className="main-text-container">
            <h1 className="main-text">
              En publicidad no hay un techo de cristal, hay un muro de hormigón armado que rodea un sector precario. Tenemos la oportunidad de liderar un cambio profundo donde sí importen las personas, los tiempos, la calidad y el impacto de lo que hacemos.
            </h1>
            <h2 className="main-text" style={{ mpaddingTop: '5px' }}>
              <span className="text-underline">Apúntate a esta lista</span> si tú también quieres empezar a “filling_the gap”.
            </h2>
          </div>

          {/* <div className="main-text-old">
            <p>
              En publicidad no hay un techo de cristal, hay un muro de hormigón armado que rodea un sector precario. Tenemos la oportunidad de liderar un cambio profundo donde sí importen las personas, los tiempos, la calidad y el impacto de lo que hacemos.
            </p>
            <span>
              <span className="text-underline">Apúntate a esta lista</span> si tú también quieres empezar a “filling_the gap”.
            </span>
            <br />
          </div> */}

          <MailchimpFormContainer />

          {/* Email */}

        </div>
      </div>

      <div className="second-container">
        <div className="second-container-title">
          <div>On the Top</div>
          <div>of <span className="hypocrite">(hypocrite)</span> Mind</div>
          <div className="hypocrite-underline">_____</div>
        </div>
      </div>


      {/* START Intentions */}
      <div className="third-container ">
        <div className="intentions">
          <div className="intentions-background" />

          <div className="intention-box">
            <div className="intention-text">
              <span>YO? YO NUNCA TRABAJÉ <span className="violet">SIN BRIEF</span>...</span>
            </div>
          </div>

          <div className="intention-box rainbow-box">
            <div className="intention-text">
              <span>Yo nunca tiRÉ a la basura una idea porque un DirectOr CreativO, <span className="violet">en nombre del Dios de la Subjetividad</span>, dijese: “no sÉ... (postura madmen)...
                le falta punch”.</span>
            </div>
            <div className="rainbow-background" />
          </div>

          <div className="intention-box" style={{ marginTop: '95px' }}>
            <div className="intention-text">
              <span>Yo nunca me SENTÍ forzada a hacer una publi que perpetúa <span className="violet">estereotipos.</span></span>
            </div>
          </div>

          <div className="intention-box">
            <div className="intention-text">
              <span>Yo nunca HICE UN DISEÑO / CLAIM / GUIÓN... urgente de DUDOSA CALIDAD. como mucho, <span className="violet">“para ayer”.</span></span>
            </div>
          </div>

          <div className="intention-box">
            <div className="intention-text">
              <span>Yo nunca me sentí en una CONSTANTE <span className="violet">contradicción</span> con <span className="violet">mis valores</span> y principios.</span>
            </div>
          </div>

          {/* Desktop */}
          <div className="intention-box show-desktop" style={{ marginTop: '30px' }}>
            <div className="intention-text">
              <span>Yo nunca me sentí una</span>
              <span style={{ color: 'white' }}>#brainstorbing</span>
              <span> en una reunión <span className="violet">por cuestión de género.</span></span>
            </div>
            <div className="brainstorbing-box">
              <span className="brainstorbing-text">#BRAINSTORBING</span>
            </div>
          </div>

          {/* Mobile */}
          <div className="intention-box show-mobile" style={{ marginTop: '30px' }}>
            <div className="intention-text">
              <span>Yo nunca me sentí una</span>
              <br />
              <span> en una reunión <span className="violet">por cuestión de género.</span></span>
            </div>
            <div className="brainstorbing-box">
              <span className="brainstorbing-text">#BRAINSTORBING</span>
            </div>
          </div>

          <div className="intention-box">
            <div className="intention-text">
              <span>Yo nunca atendí más <span className="violet">“urgencias” que un hospital.</span> Tengo el concepto superclaro...</span>
            </div>
          </div>

          <div className="intention-box">
            <div className="intention-text">
              <span>Yo nunca me sentí <span className="violet">parte de un experimento de Pavlov</span> al recibir peticiones de cliente imposibles de ejecutar (incluso) bajo la LóGICA o LA Ley Universal del desdoblamiento de Espacio-Tiempo.</span>
            </div>
          </div>

          <div className="intention-box">
            <div className="intention-text">
              <span>Yo nunca pensé en dejar esto de la creatividad y <span className="violet">presentarme a unas opos</span>, demasiada calidad de vida...</span>
            </div>
          </div>
        </div>
      </div>
      {/* END Intentions */}


      <div className="fourth-container">
        <div className="row-face ">
          <div className="violet-face" />
        </div>
        <div className="row-face">
          <div className="yellow-face" />
        </div>
        <div className="contact-container contact-container-text">
          <div>Ayuda a que llegue a más</div>
          <div><b>#publitransformadoras</b></div>
          <div>o <b>contacta conmigo</b></div>
          <div className="contact-icons">
            <a className="social-icon" href="https://www.instagram.com/filling_thegap/"><i className="fab fa-instagram fa-2x"></i></a>
            <a className="social-icon" href="https://twitter.com/fillingthegap_"><i className="fab fa-twitter fa-2x"></i></a>
            <a className="social-icon" href="https://www.linkedin.com/in/iriaoterofernandez/"><i className="fab fa-linkedin-in fa-2x"></i></a>
            <a className="social-icon" href="mailto:publitransformadoras@gmail.com"><i className="far fa-envelope fa-2x"></i></a>
          </div>
        </div>
        <div className="row-face" style={{ height: '75px' }}>
          <div className="violet-face" style={{ left: '40px', top: '20px' }} />
        </div>

      </div>



    <TermsAndConditions />
    <PrivacyPolicy />
    <CookiesView />


      <div className="footer">
        <div className="footer-text" data-bs-toggle="modal" data-bs-target="#termsAndConditionsModal">Términos y condiciones</div>
        <div className="footer-text" data-bs-toggle="modal" data-bs-target="#privacyPolicyModal">Política de privacidad</div>
        <div className="footer-text" data-bs-toggle="modal" data-bs-target="#cookiesModal">Cookies</div>
        <hr style={{color:'white', marginTop: '0px'}}/>
        <div className="copyright-text">© 2022 <b>Publitransformadoras</b>. Todos los derechos reservados.</div>
      </div>


    </div>
  );
}

export default HomeScreen;
