import { types } from "../types/types";

const initialState = {
    newsletters: [],
    active: null
}

export const newslettersReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.newsletterActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }

        case types.newsletterLoad:
            return {
                ...state,
                newsletters: [...action.payload]
            }

        case types.newsletterSelected:
            return {
                ...state,
                active: action.payload
            }

        case types.newsletterDeselected:
            return {
                ...state,
                active: {}
            }
        
        case types.newsletterUpdate:
            console.log(state);
            return {
                ...state,
                newsletters: state.newsletters.map(
                    newsletter => newsletter.id === action.payload.id 
                        ? action.payload.newsletter
                        : newsletter
                )
            }
    
        default:
            return state;
    }
    
}