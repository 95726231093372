import Swal from 'sweetalert2';
import 'animate.css';

import { getAuth, signInWithEmailAndPassword, signOut } from "../firebase/firebaseConfig";
import { types } from "../types/types";
import { finishLoading, startLoading } from "./ui";

export const startLoginEmailPassword = (email, password) => {
    return (dispatch) => {

        dispatch(startLoading());

        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                dispatch(login(userCredential.user.uid, userCredential.user.email));

                dispatch(finishLoading());
            })
            .catch((error) => {
                dispatch(finishLoading());

                let errorMessage = '';
                if (error.message === ('Firebase: Error (auth/wrong-password).')
                    || error.message === ('Firebase: Error (auth/user-not-found).')) {
                    errorMessage = 'Email o contraseña no válidos';
                } else {
                    errorMessage = error.message;
                }

                Swal.fire({
                    icon: 'error',
                    title: 'Ups...',
                    text: errorMessage,
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    }
                });
            });
    }
}

export const login = (uid, displayName) => ({
    type: types.login,
    payload: {
        uid,
        displayName
    }
});


export const startLogout = () => {
    return async (dispatch) => {
        const auth = getAuth();
        await signOut(auth);

        dispatch(logout());
    }
}

export const logout = () => {
    return {
        type: types.logout
    }
}