import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { startLogout } from '../../../actions/auth';

import './NavBar.css';

export const NavBar = ({ isLoggedIn }) => {
    const image = require('../../../assets/YellowFace.png').default;

    const dispatch = useDispatch()

    const handleLogout = () => {
        dispatch(startLogout());
    }

    return (
        <nav className="navbar navbar-dark bg-transparent fixed-top">
            <div className="container-fluid">
                <button id="menu-button" className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                            <img src={image} className="gapy-image" alt="..." />
                        </h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                            <li>
                                <NavLink className={({ isActive }) => 'nav-item navbarbtn' + (isActive ? ' active' : '')} to="/" data-bs-dismiss="offcanvas" aria-label="Close">
                                    # FILLINGTHEGAP #
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className={({ isActive }) => 'nav-item navbarbtn' + (isActive ? ' active' : '')} to="/newsletters" data-bs-dismiss="offcanvas" aria-label="Close">
                                    # Newsletters #
                                </NavLink>
                            </li>
                            {isLoggedIn &&
                                <div>
                                    <li>
                                        <NavLink className={({ isActive }) => 'nav-item navbarbtn' + (isActive ? ' active' : '')} to="/admin" data-bs-dismiss="offcanvas" aria-label="Close">
                                            # Admin #
                                        </NavLink>
                                    </li>
                                    <li>
                                        <div className="nav-item mybtn-red" onClick={handleLogout} data-bs-dismiss="offcanvas" aria-label="Close">Logout</div>
                                    </li>
                                </div>

                            }
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}
