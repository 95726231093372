export const types = {
    login: '[Auth] Login',
    logout: '[Auth] Logout',
    
    uiStartLoading: '[UI] Start loading',
    uiFinishLoading: '[UI] Finish loading',

    newsletterAddNew: '[Newsletters] New newsletter',
    newsletterActive: '[Newsletters] Newsleter active',
    newsletterSelected: '[Newsletters] Newsleter selected',
    newsletterLoad: '[Newsletters] Load newsletters',
    newsletterUpdate: '[Newsletters] Updated newsletter',
    newsletterImageUrl: '[Newsletters] Update image url',
    newsletterDelete: '[Newsletters] Delete newsletter',
    newsletterCleaning: '[Newsletters] Logout cleaning'

}