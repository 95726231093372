import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getAuth, onAuthStateChanged } from "../firebase/firebaseConfig";

import { NavBar } from '../components/ui/NavBar/NavBar';
import { ArchiveScreen } from '../components/archive/ArchiveScreen';
import { HomeScreen } from '../components/home/HomeScreen';
import { LoginScreen } from '../components/auth/LoginScreen';
import { NewslettersScreen } from '../components/newsletters/NewslettersScreen';
import { PrivateRoute } from './PrivateRoute';

import { login } from "../actions/auth";
import { LorginRoute } from './LoginRoute';
import { startLoadingNewsletters } from '../actions/newsletter';

export const AppRouter = () => {

    const dispatch = useDispatch();

    const [checking, setChecking] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
            if (user?.uid) {
                dispatch(login(user.uid, user.email));
                setIsLoggedIn(true);

                dispatch(startLoadingNewsletters());
            } else {
                setIsLoggedIn(false);
            }

            setChecking(false);
        });
    }, [dispatch, setChecking, setIsLoggedIn]);

    if (checking) {
        return (
            // Loading screen
            <div className="auth__main">
                <div className="auth__box-container">
                    <div className="text-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <BrowserRouter>

            <NavBar isLoggedIn={isLoggedIn} />

            <Routes>
                <Route exact path='/auth/login' element={<LorginRoute isLoggedIn={isLoggedIn} />}>
                    <Route path="/auth/login" element={<LoginScreen />} />
                </Route>
                <Route exact path='/admin' element={<PrivateRoute isLoggedIn={isLoggedIn} />}>
                    <Route exact path='/admin' element={<NewslettersScreen />} />
                </Route>
                <Route exact path="/" element={<HomeScreen />} />
                <Route exact path="newsletters" element={<ArchiveScreen />} />
                <Route path="*" element={<HomeScreen />} />
            </Routes>
        </BrowserRouter>
    )
}
